export const environment = {
  environment: 'TEST',
  production: true,
  loginRedirectUrl: 'https://sdtest.agnicoeagle.com/',
  logoutRedirectUrl: 'https://sdtest.agnicoeagle.com/auth',
  backendBaseUrl:
    'https://aemapdscheduledeliverybackend-test.azurewebsites.net/api',
  clientId: 'e54b45f0-4b8d-437b-920a-ed74452d2a2c',
  authority:
    'https://login.microsoftonline.com/5f4e175c-44de-4676-ae70-23ec941f455d',
  AccessScope: 'api://628202fa-d3a0-4123-8367-33899edee5a7/access_as_user',
  arcgis: {
    accessToken:
      'AAPK3d037583d5b54e8c811d76bc586b6f48z_MJZ4RJ2HVwt6CcpqBAhIUn8dTvJS4jMDWbMIeblqZh6MIzs5Iij-K_IBnDn-Dd',
  },
};
